import { Injectable, OnDestroy } from '@angular/core';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService implements OnDestroy {
  private flags: LDFlagSet;
  private ldClient: LDClient;
  // eslint-disable-next-line @typescript-eslint/ban-types
  private ldInit: Function = initialize;
  private cloudTrailV2$: BehaviorSubject<boolean>;
  private readonly anonymousUserKey: string = 'OB*#RHREhfi2uewy79ds'; // Random

  public get getCloudTrailV2$(): BehaviorSubject<boolean> {
    // console.log('getCloudTrailV2 ==> ', this.cloudTrailV2$);

    return this.cloudTrailV2$;
  }

  public constructor() {
    this.initSubjects();
    // console.log('ldClient', this.ldClient);
  }

  public ngOnDestroy(): void {
    this.cloudTrailV2$.complete();
  }

  public async setUser(loggedInUser: any): Promise<void> {
    if (this.ldClient === undefined) {
      this.initLaunchDarkly();
    }

    this.ldClient.identify({
      anonymous: false,
      email: loggedInUser.email,
      firstName: loggedInUser.firstName,
      key: loggedInUser.email,
      kind: 'user',
      lastName: loggedInUser.lastName,
      name: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
    });

    // console.log('loggedin usser', loggedInUser);
  }

  public initLaunchDarkly(): void {
    // console.log('ldClient', this.ldClient);

    this.flags = {
      'cloud-trail-v2': false,
    }; // Default values of features

    this.ldClient = this.ldInit(
      '65ab14d03da9521002a79d9f', // string from Launch Darkly
      {
        anonymous: true,
        key: this.anonymousUserKey,
        kind: 'user',
      }
    );

    this.ldClient.on('ready', this.handleReady.bind(this));
    // console.log('ldclient ready', this.ldClient);

    this.ldClient.on(
      'cloud-trail-v2',
      this.handleCloudTrailV2FeatureFlagChange.bind(this)
    );
  }
  private initSubjects(): void {
    this.cloudTrailV2$ = new BehaviorSubject<boolean>(false);
  }

  private handleReady(): void {
    // console.log('hi we are ready ');

    // Current feature flag settings from Launch Darkly
    this.flags = this.ldClient.allFlags();
    // console.log('feature flag settings ==> ', this.flags);

    this.cloudTrailV2$.next(this.flags?.CloudTrail);
    // console.log('feature flag settings ==> ' + this.flags);
  }

  private handleCloudTrailV2FeatureFlagChange(value: boolean): void {
    this.flags['cloud-trail-v2'] = value;
    // console.log('val from service', value);

    this.cloudTrailV2$.next(value);
  }
}
