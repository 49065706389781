import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlurHelperService {
  public enableBlur =
    localStorage.getItem('demoMode') === 'true' ? true : false;
  public blurModeChanged: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.enableBlur
  );

  constructor() {}

  setBlur(val) {
    this.enableBlur = Boolean(val);
    this.blurModeChanged.next(val);
    localStorage.setItem('demoMode', val);
  }
}
