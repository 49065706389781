import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { CommonService } from 'src/app/shared/service/common.service';
import { RolesService } from 'src/app/shared/service/roles.service';
import { HelpSystemSidebarModel } from './help-system-sidebar.model';

@Component({
  selector: 'nio-help-sidebar',
  templateUrl: './help-sidebar.component.html',
  styleUrls: ['./help-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('animation', [
      state('void', style({ height: '0px', opacity: 0 })),
      state('*', style({ height: '*', opacity: 1 })),
      transition(':enter', animate('200ms ease-out')),
      transition(':leave', animate('200ms ease-in')),
    ]),
  ],
})
export class HelpSidebarComponent implements OnInit {
  isAdmin = false;
  isCollapsed = true;
  loading = true;
  helpSystemErrorMessage =
    'Error while getting Help Item, Please close and try again.';
  helpDescriptionData = [];
  constructor(
    public router: Router,
    public commonService: CommonService,
    private rolesService: RolesService
  ) {}

  ngOnInit(): void {
    this.checkUserRole();
    this.subscribeHelpId();
  }

  isValidLogin() {
    return (
      this.router.url !== '/' &&
      this.router.url.indexOf('redirect') === -1 &&
      this.router.url.indexOf('unauthorized') === -1
    );
  }

  closeHelp() {
    if (!this.commonService.clickHamburgerHelp.value) {
      this.commonService.clickHamburgerHelp.next(true);
    }
  }

  subscribeHelpId() {
    this.commonService.newHelpId.subscribe((helpId) => {
      if (helpId) {
        this.loading = true;
        this.commonService.selectedHelpItem = null;
        this.commonService.selectedHelpId = helpId;
        this.getHelpData(helpId);
      }
    });
  }

  getHelpData(helpId) {
    this.commonService.getHelpSystemItem(helpId).subscribe(
      (helpRes: HelpSystemSidebarModel) => {
        this.loading = false;
        this.commonService.selectedHelpItem = helpRes;
        this.formatHelpResult();
      },
      (err) => {
        this.loading = false;
        this.commonService.errorMessage(this.helpSystemErrorMessage);
      }
    );
  }
  formatHelpResult() {
    if (this.commonService.selectedHelpItem) {
      this.helpDescriptionData =
        this.commonService.selectedHelpItem.description.split('.');
    }
  }
  checkUserRole() {
    this.isAdmin = this.rolesService.checkIfUserIsAdmin();
  }
}
