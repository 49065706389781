import { Component, Input } from '@angular/core';

@Component({
  selector: 'nio-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss'],
})
export class NewsItemComponent {
  @Input() featured;
  @Input() header;
  @Input() imgSrc;
  @Input() title;
  @Input() time;
  @Input() description;
  @Input() linkText;
  @Input() url;

  constructor() {}
}
