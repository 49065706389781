import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CloudServiceAgreementService {
  constructor(private apiService: ApiService) {}

  acceptCloudServiceAgreement(agreementData) {
    return this.apiService.post(`sign-cloud-services-agreement`, agreementData);
  }
  getAgreementAcceptance(customerID) {
    return this.apiService.get(`customer-info?customer_id=${customerID}`,
    );
  }
  getcompanyNameForAgreement(customerID){
    return this.apiService.get(`get-company-name?customer_id=${customerID}`);
  }
  getCloudAgreementData(){
    return this.apiService.get('list-cloud-services-agreements');
  }
  getCloudAgreementPresignedUrl(customer_name?){
    return this.apiService.get('list-cloud-services-agreements/get-csa-presigned-url',{
      customer_name,
    });
  }
}
