<mat-list
  [ngClass]="isCollapsed ? 'collapsed' : 'expanded'"
  *ngIf="isValidLogin()"
>
  <button
    (click)="toggle()"
    id="menu-toggler"
    class="menu-toggler"
    mat-icon-button
    disableRipple
  >
    <img src="assets/icons/burger-menu.svg" alt="menu" />
  </button>
  <ng-container *ngFor="let item of items">
    <mat-list-item
      class="no-padding"
      *ngIf="
        isAdmin ||
        item?.canAccess ||
        (item?.isCustomerAdmin && item?.isCustomerAdminCanAccess) ||
        (isPresalesEngineer && item?.isPresalesEngineer) ||
        (isAccountManager && item?.isAccountManager)
      "
    >
      <a [routerLink]="item.link" routerLinkActive="active" class="link-navi"
        ><span class=" {{ item.image }}"></span>
        <span class="navi-text"> {{ item?.name }}</span>
      </a>
    </mat-list-item>

    <ng-container
      *ngIf="
        router.url.includes(item.link) &&
        (commonService.clickHamburger | async) === false
      "
    >
      <ng-container *ngFor="let subItem of item.subLinks | sortArr : 'asc'">
        <mat-list-item
          [@animation]
          class="sub-item"
          *ngIf="
            subItem?.isShownFlag &&
            (subItem?.canAccess ||
              isAdmin ||
              subItem?.isCustomerAdmin ||
              (isPresalesEngineer && subItem?.isPresalesEngineer) ||
              (isAccountManager && subItem?.isAccountManager))
          "
        >
          <a
            [routerLink]="subItem.link"
            routerLinkActive="active"
            class="sublink link-navi"
            >{{ subItem.name }}</a
          >
        </mat-list-item>
        <div *ngIf="router.url.includes('operate/control-access')">
          <ng-container *ngFor="let datapageitem of subItem?.dataPages">
            <mat-list-item
              [@animation]
              class="sub-item pl-5"
              *ngIf="
                datapageitem?.canAccess ||
                isAdmin ||
                datapageitem?.isCustomerAdmin ||
                (isPresalesEngineer && datapageitem?.isPresalesEngineer) ||
                (isAccountManager && datapageitem?.isAccountManager)
              "
            >
              <a
                [routerLink]="datapageitem?.link"
                routerLinkActive="active"
                class="sublink link-navi"
                >{{ datapageitem?.name }}</a
              >
            </mat-list-item>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <a (click)="giveFeedback()" *ngIf="!isCollapsed" class="feedback"
    >Give feedback</a
  >
  <span
    (click)="giveFeedback()"
    title="Give feedback"
    *ngIf="isCollapsed"
    class="material-icons feedback-icon"
  >
    thumb_up_alt
  </span>
</mat-list>
