import { RestrictedAccessComponent } from './shared/components/restricted-access/restricted-access.component';
import { RoleGuard } from './modules/core/guard/role.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { AuthGuard } from './modules/core/guard/auth.guard';
import { AdminGuard } from './modules/core/guard/admin.guard';
import { RedirectComponent } from './components/redirect/redirect.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { MappingComponent } from './components/mapping/mapping.component';
import { LargeTexasCityComponent } from './components/case-studies/large-texas-city/large-texas-city.component';
import { HealthCareLandingZoneComponent } from './components/case-studies/health-care-landing-zone/health-care-landing-zone.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },

  {
    path: '401',
    component: RestrictedAccessComponent,
  },
  {
    path: 'mapping/:shortUrl',
    // canActivate: [AuthGuard, RoleGuard],
    component: MappingComponent,
  },
  {
    path: 'case-studies/large-texas-city',
    component: LargeTexasCityComponent,
  },
  {
    path: 'case-studies/health-care-landing-zone',
    component: HealthCareLandingZoneComponent,
  },
  {
    path: 'measure',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./modules/measure/measure.module').then((m) => m.MeasureModule),
  },
  {
    path: 'operate',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./modules/operate/operate.module').then((m) => m.OperateModule),
  },
  {
    path: 'deploy',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./modules/deploy/deploy.module').then((m) => m.DeployModule),
  },
  {
    path: 'migration',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () =>
      import('./modules/migration/migration.module').then(
        (m) => m.MigrationModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/train/train.module').then((m) => m.TrainModule),
  },
  {
    path: 'tools',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/pricing-tools/pricing-tools.module').then(
        (m) => m.PricingToolsModule
      ),
  },
  {
    path: 'support',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./modules/support/support.module').then((m) => m.SupportModule),
  },
  {
    path: 'profile',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },

  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/operate/operate.module').then(
            (m) => m.OperateModule
          ),
      },
    ],
  },

  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
