// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://dy693b43tg.execute-api.us-east-2.amazonaws.com/dev',
  appVersion: require('../../package.json').version,
  identityProviders: {
    defaultConfig: 'auth0', // Set the default identity provider
    azure: {
      issuer:
        'https://login.microsoftonline.com/0dac241e-b67d-4090-9694-3ea626043d2d/v2.0',
      useSilentRefresh: true,
      redirectUri: window.location.origin + '/redirect',
      postLogoutRedirectUri: window.location.origin,
      clientId: '23e7ef42-be88-491e-b9e8-2275f0b5f7fc',
      silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
      responseType: 'code',
      scope: 'openid profile email offline_access',
      clientSecret: 'wur8Q~SoXNgt_or2i_puRDKLKme7V1z7bAzzEcFg',
      // turn off validation that discovery document endpoints start with the issuer url defined above
      strictDiscoveryDocumentValidation: false,
    },
    auth0: {
      // Url of the Identity Provider
      issuer: 'https://staging-netsync.us.auth0.com/',
      useSilentRefresh: true,
      // URL of the SPA to redirect the user after login
      redirectUri: window.location.origin + '/redirect',
      // URL of the SPA to redirect the user after logout
      postLogoutRedirectUri: window.location.origin,
      // The SPA's id. The SPA is registerd with this id at the auth-server
      clientId: 'm7BpONwGfhnzIB5Cj9aSOiaiGlTJvHDB',
      // URL of the SPA to redirect the user after silent refresh
      silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
      // Response type
      responseType: 'code',
      // responseType: 'urn:ietf:params:oauth:grant-type:device_code',
      // set the scope for the permissions the client should request
      // The first three are defined by OIDC. The 4th is a usecase-specific one
      scope: 'openid profile email offline_access',
      logoutUrl: 'https://staging-netsync.us.auth0.com/v2/logout',

      showDebugInformation: true,
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
