import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortArr'
})
export class SortArrPipe implements PipeTransform {

  transform(value: Array<any>, sortType: string): Array<any> {
    const arr = value;
    if(sortType === 'asc') {
      return arr?.sort((a,b) => {
        if(a?.name) {
          return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
        } else {
          return (a > b) ? 1 : ((b > a) ? -1 : 0);
        }
      });
    } else if(sortType === 'desc') {
      return arr?.sort((a,b) => {
        if(a?.name) {
          return (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0);
        } else {
          return (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0);
        }
      });
    } else {
      return arr;
    }

  }

}
