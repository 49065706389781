<h4 mat-dialog-title>Account Manager Information</h4>
<div mat-dialog-content class="content">
  <p class="warn" *ngIf="data?.accountsManagers?.length === 0 || !data?.accountsManagers">There is no Account Manager Information available. </p>
  <table class="account-managers-table" *ngIf="data?.accountsManagers?.length > 0">
    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Job Title</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data?.accountsManagers">
        <td>{{ item?.name }}</td>
        <td>{{ item?.email }}</td>
        <td>{{ item?.phone_no }}</td>
        <td>{{ item?.job_title }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close color="primary">Close</button>
</div>
