<div class="container" style="height: 10rem"></div>
<div class="container">
  <div class="landing-section">
    <mat-card>
      <mat-card-content>
        <img
          src="/assets/icons/measure-icon.svg"
          alt="Measure.sv"
          class="landing-icon"
        />
        <div class="mat-card-inside-content">
          <h2 class="card-title">Measure</h2>
          <p class="card-text">
            All your operational measurements in one centrally located and
            customizable location.
          </p>
        </div>
      </mat-card-content>
      <mat-card-actions class="custom-card-footer">
        <a (click)="scroll('measure-section')" class="link learn-link"
          >Learn more</a
        >
        <span class="material-icons custom-icon"> arrow_right </span>
      </mat-card-actions>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <img
          src="/assets/icons/deploy-icon.svg"
          class="img-fluid landing-icon"
          alt="deploy icon"
        />
        <div class="mat-card-inside-content">
          <h2 class="card-title">Deploy</h2>
          <p class="card-text">
            Curated and tested automations that allow organizations to implement
            new workloads faster.
          </p>
        </div>
      </mat-card-content>
      <mat-card-actions class="custom-card-footer">
        <a (click)="scroll('deploy-section')" class="link learn-link"
          >Learn more</a
        >
        <span class="material-icons custom-icon"> arrow_right </span>
      </mat-card-actions>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <img
          src="/assets/icons/operate-icon.svg"
          alt="Operate.sv"
          class="img-fluid landing-icon"
        />
        <div class="mat-card-inside-content">
          <h2 class="card-title">Operate</h2>
          <p class="card-text">
            Manage your existing workloads from multiple OEMs in a single
            location.
          </p>
        </div>
      </mat-card-content>
      <mat-card-actions class="custom-card-footer">
        <a (click)="scroll('operate-section')" class="link learn-link"
          >Learn more</a
        >
        <span class="material-icons custom-icon"> arrow_right </span>
      </mat-card-actions>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <img
          src="/assets/icons/train-icon.svg"
          alt="Train.sv"
          class="img-fluid landing-icon"
        />
        <div class="mat-card-inside-content">
          <h2 class="card-title">Train</h2>
          <p class="card-text">
            Stay current on the developing Cloud, DevOps, and Security
            technologies.
          </p>
        </div>
      </mat-card-content>
      <mat-card-actions class="custom-card-footer">
        <a (click)="scroll('train-section')" class="link learn-link"
          >Learn more</a
        >
        <span class="material-icons custom-icon"> arrow_right </span>
      </mat-card-actions>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <img
          src="/assets/icons/support-icon.svg"
          alt="Support.sv"
          class="img-fluid landing-icon"
        />
        <div class="mat-card-inside-content">
          <h2 class="card-title">Support</h2>
          <p class="card-text">
            Plans that allow any size customer to access the support they need
            to be successful.
          </p>
        </div>
      </mat-card-content>
      <mat-card-actions class="custom-card-footer">
        <a (click)="scroll('support-section')" class="link learn-link"
          >Learn more</a
        >
        <span class="material-icons custom-icon"> arrow_right </span>
      </mat-card-actions>
    </mat-card>
  </div>
  <!-- /.row -->

  <!-- Recent News -->
  <div class="pd-y" id="partners-title">
    <div class="section-header">
      <h2 class="section-title">recent news</h2>
      <span class="top"></span>
    </div>

    <owl-carousel-o class="carousel" [options]="carouselOptions">
      <ng-container *ngFor="let item of netsyncItems">
        <ng-template carouselSlide>
          <div class="trend-right-news">
            <div class="trend-right-img">
              <img [src]="item.imgSrc" />
            </div>
            <div class="trend-right-cap">
              <h4>
                <a [href]="item.url" target="_blank">{{ item.title }} </a>
              </h4>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>

  <!-- Case Studies -->
  <div class="pd-y" id="partners-title">
    <div class="section-header">
      <h2 class="section-title">Case Studies</h2>
      <span class="top"></span>
    </div>

    <owl-carousel-o class="carousel" [options]="caseStudiesCarouselOptions">
      <ng-container *ngFor="let item of caseStudies">
        <ng-template carouselSlide>
          <div class="trend-right-news">
            <div class="trend-right-img">
              <img [src]="item.imgSrc" alt="case-studies" />
            </div>
            <div class="trend-right-cap">
              <h4>
                <a [href]="item.url" target="_blank">{{ item.title }} </a>
                <p>Case Study</p>
              </h4>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>

<!-- overview items-->
<nio-overview></nio-overview>
<!-- ./overview items -->

<!-- news section-->

<div class="news pd-y" id="news-section">
  <div class="section-header">
    <h2 class="section-title">recent news</h2>
    <span class="line"></span>
  </div>
  <div class="news-grid">
    <nio-news-item
      *ngFor="let item of newsItems | slice : 0 : 2"
      [imgSrc]="item.imgSrc"
      [title]="item.title"
      [time]="item.time"
      [description]="item.description"
      [url]="item.url"
    ></nio-news-item>
    <div class="featured">
      <nio-news-item
        [title]="header.title"
        [description]="header.description"
        [header]="true"
      >
      </nio-news-item>
      <nio-news-item
        [featured]="true"
        [imgSrc]="newsItems[2].imgSrc"
        [title]="newsItems[2].title"
        [time]="newsItems[2].time"
        [description]="newsItems[2].description"
        [url]="newsItems[2].url"
      ></nio-news-item>
    </div>
    <nio-news-item
      *ngFor="let item of newsItems | slice : -2"
      [imgSrc]="item.imgSrc"
      [title]="item.title"
      [time]="item.time"
      [description]="item.description"
      [url]="item.url"
    ></nio-news-item>
  </div>
</div>

<!-- /. news section-->
<nio-footer></nio-footer>
