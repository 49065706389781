import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionInformationService } from '../../service/session-information.service';
import { AuthService } from 'src/app/auth.service';
import { CommonService } from '../../service/common.service';
import { FeedbackService } from '../../service/feedback.service';
import { MatDialogRef } from '@angular/material/dialog';
/* eslint-disable */

@Component({
  selector: 'nio-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  $feedbackRequest;
  feedbackForm: FormGroup;
  nameControl: FormControl = new FormControl('', [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<FeedbackComponent>,
    private feedbackService: FeedbackService,
    private sessionInfo: SessionInformationService,
    private authService: AuthService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.feedbackForm = new FormGroup({
      message: new FormControl('', [
        Validators.required,
        Validators.minLength(25),
      ]),
      emotion: new FormControl('', [Validators.required]),
    });
  }

  getControlErrors(controlName, error) {
    if (this.feedbackForm.controls[controlName].errors) {
      return this.feedbackForm.controls[controlName].errors[error];
    }
  }

  onConfirm(value) {
    const browser = this.sessionInfo.getBrowserInfo();
    const identity: any = this.authService.getClaims();
    const payload = {
      email: identity.email,
      browser: browser.name,
      browser_version: browser.version,
      os: this.sessionInfo.getOperatingSystem(),
      page: window.location.pathname.split('').splice(1).join(''),
      message: value.message,
      emotion: value.emotion,
    };

    this.dialogRef.close();

    if (!this.$feedbackRequest) {
      this.$feedbackRequest = this.feedbackService
        .sendFeedback(payload)
        .subscribe(
          (res: any) => {
            this.commonService.successMessage(res.message);
          },
          (err) => {
            this.commonService.errorMessage(err?.error?.message);
          }
        );
    }
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close({ confirm: false });
  }
}
