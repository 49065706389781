<div class="container">
  <div class="row">
    <div class="col-lg-12">
      <h1>Large City Security Implementation</h1>
      <h1>Case Study</h1>

      <h2>The Customer Challenge</h2>
      <p>
        One of the largest Cities in the United States was moving from one AWS
        Partner to Netsync and they needed assistance with an AWS infrastructure
        design that would improve overall governance and security as well as
        bring distinct divisions of the City’s AWS billing together in a single
        business intelligence dashboard while allowing them to maintain four
        intentionally separated AWS Payer Accounts.
      </p>

      <h2>Governance Requirements</h2>
      <p>
        The customer needed to transition twenty eight unique AWS Accounts from
        their current AWS Partner to Netsync. During this transition, these
        accounts would be grouped into four specific City organizations to
        better support the needs of the City’s accounting department.
      </p>
      <p>
        Do to this need, each organization required its own AWS Payer Account,
        AWS Control Tower, Centralized Billing, and organization specific guard
        rails.
      </p>

      <h2>Financial Management and Cost Optimization Requirements</h2>
      <p>
        The Technology Vice President needed a centralized dashboard capable of
        combining AWS Cost and Usage (CUR) reporting into a single business
        intelligence system. This system needed the ability to measure AWS
        expense at the Payer Account, Workload Account, and Workload Tagging
        levels.
      </p>
      <p>
        This type of system is especially important because they were
        transitioning between AWS Partners. Their previous partner had required
        the City to keep their accounts within a larger, partner controlled, AWS
        Payer Account.
      </p>
      <p>
        During the partner transition, these accounts would be moved to Netsync
        and made part of four different stand alone payers to accommodate the
        City’s financial spending requirements. The City also needed Netsync’s
        invoicing delivered to different contacts and addresses so the proper
        expense departments would pay the proper invoices.
      </p>

      <h2>Cyber Security Requirements</h2>
      <p>
        The customer holds data on the citizens as well as law enforcement
        activities that would be valuable to thieves to use for identity theft
        or other ransom activities.
      </p>
      <p>
        Protecting against data exfiltration is an important security concern
        and the customer requires a centralized security inspection system in
        their AWS environment that will allow them to use native AWS Security
        Services as well as third party virtual security appliances to inspect
        traffic traveling between their AWS accounts and the Internet (North /
        South) as well as traffic traveling between internal server groups and
        other client owned AWS accounts (East / West).
      </p>

      <h2>The Implemented Solution Set</h2>
      <h2>Governance Implementation Solution</h2>
      <p>
        Using AWS Organizations Services, built into the AWS Control Tower
        solution, brought several efficiencies allowing our customer to quickly
        monitor their AWS spend and implement controls within their
        organization. The AWS Control Tower Solution also brings best practices
        around centralized logging and auditing as required by the customer’s
        CISO.
      </p>
      <p>
        Solutions such as centralized cost and usage reporting allow the
        customer to solve their requirements for Payer and Workload level cost
        reporting and Organizational Tagging Policies assisted in standardizing
        tagging across resources throughout the customer’s AWS organization.
      </p>
      <p>
        Furthermore, AWS Control Tower Guard Rails are now providing base level
        controls to each of the City’s divisions within their own AWS
        Organization. This will allow each City division to implement data
        sovereignty and access controls to force workload tagging and prevent
        deletion of data without an MFA token.
      </p>
      <img src="/assets/case-studies/large-texas-city-1.jpg" />

      <h2>
        Financial Management and Cost Optimization Implementation Solution
      </h2>
      <p>
        Netsync provides a pre-built business intelligence platform called
        NetsyncIO that allows the customer to quickly understand Cost and Usage
        (CUR) information without the need to build custom dashboards or provide
        end user access to the AWS console. The customer can easily see the
        spend of their entire organization at one time or filter the resulting
        data based on any field available to them in the CUR. This includes
        Linked (Workload) Accounts, AWS Service Types, AWS Regions, Custom
        Tagging and much more.
      </p>
      <p>
        NetsyncIO’s ability to see multiple AWS Payer Accounts and all of their
        associated linked workload accounts proved valuable to this customer
        because they were able to pull in all of the associated Cost and Usage
        Reporting logs into a single unified interface. This solved the City’s
        challenge of being able to analyze and report in multiple AWS Payer
        accounts while providing granular access control to the different
        political divisions of the City.
      </p>

      <h2>Cyber Security Implementation Solution</h2>
      <p>
        Netsync implemented a centralized Security Network account and shared it
        with the other political division’s AWS accounts as needed. This allows
        each City division to send it’s traffic via a known and secure AWS
        Transit Gateway attachment. This allows each division to consume the
        City’s Cyber Security assets as a shared resource and ensure data is
        examined for exfiltration risks as required by City policy.
      </p>
      <p>
        Diagram represents how traffic flows within each isolated application
      </p>
      <img src="/assets/case-studies/large-texas-city-2.jpg" />

      <h2>The Result</h2>
      <p>
        Netsync worked with the customer to dive deep to gain a clear
        understanding of their existing AWS environment including network,
        security, and workloads. This allowed Netsync to work closely with the
        customer to develop a detailed deployment plan for their new centralized
        security and inspection design.
      </p>
      <p>
        Netsync also implemented financial cost management that meets the needs
        of an existing large organization that is going to continue to grow and
        requires a detailed level of cost management to ensure tax payer funding
        is used in the most economical way possible.
      </p>
      <p>
        This work resulted in a safe, secure, and cost optimized AWS Landing
        Zone and security inspection VPC implementation allowing the customer to
        deploy new workloads using a know deployment pattern producing
        infrastructure in compliance with the customer’s operational and
        security requirements.
      </p>

      <h2>About Netsync</h2>
      <p>
        Based in Houston, TX, Netsync uses a blended approach that is both
        consultative and collaborative. We work with our clients to assess their
        needs, architect innovative technology solutions to meet those needs,
        and offer 24/7 access to our team of engineering experts who exceed
        clients’ expectations. This approach has earned Netsync numerous
        customer service excellence awards and recognition as a progressive
        partner that introduces the newest, best-of-breed products and solutions
        to clients.
      </p>
    </div>
  </div>
</div>
