/* eslint-disable */

export class AppConstant {
  public static UserRole = {
    USER: 'Netsyncio User',
    ADMIN: 'Netsyncio Admin',
    CUSTOMERAdmin: 'Netsyncio Customer Admin',
    PRESALESEngineers: 'Pre Sales Engineers',
    ACCOUNTMANAGER: 'Netsync Account Manager',
  };
  public static Roles = Object.keys(AppConstant.UserRole).map(
    (key) => AppConstant.UserRole[key]
  );
  public static UnAuthorizedErrorMessage =
    'You do not currently have permissions to access this content';
  public static RegexPattern = {
    ipAddress:
      '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
  };
  public static DateFormat = 'MMM dd yyyy';
  public static Locale = 'en';
}

export const carouselOptions = {
  loop: true,
  mouseDrag: false,
  touchDrag: false,
  pullDrag: false,
  dots: true,
  navSpeed: 700,
  navText: ['', ''],
  center: true,
  animateOut: 'fadeOut',
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    740: {
      items: 3,
    },
    940: {
      items: 3,
    },
  },
  autoplay: true,
  autoplayTimeout: 2000,
  nav: false,
};

export const caseStudiesCarouselOptions = {
  loop: false,
  mouseDrag: false,
  touchDrag: false,
  pullDrag: false,
  dots: true,
  navSpeed: 700,
  navText: ['', ''],
  center: false,
  animateOut: 'fadeOut',
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    740: {
      items: 3,
    },
    940: {
      items: 3,
    },
  },
  autoplay: true,
  autoplayTimeout: 5000,
  nav: false,
};

export const AMAZON_APP_STREAM_TITLE = 'Amazon App Stream';
export const CREATE_CUSTOMER_ACCOUNT = 'Customer Account';
export const DEPLOY_NETSYNCIO_TILLE = 'Deploy Netsyncio';
export const NETSYNC_INTEGRATED_LEARNING_ENVIRONMENT =
  'Netsync Integrated Learning Environment';
export const TRAIN_API_URL =
  'https://aws.amazon.com/api/dirs/items/search?item.directoryId=getting-started-content&sort_by=item.additionalFields.sortOrder&sort_order=asc&size=18&item.locale=en_US&tags.id=getting-started-content%23category%23databases%7Cgetting-started-content%23category%23mobile%7Cgetting-started-content%23category%23containers%7Cgetting-started-content%23category%23machine-learning%7Cgetting-started-content%23category%23dev-tools%7Cgetting-started-content%23category%23storage%7Cgetting-started-content%23category%23compute%7Cgetting-started-content%23category%23serverless%7Cgetting-started-content%23category%23account-management%7Cgetting-started-content%23category%23analytics';
