import { AuthService } from 'src/app/auth.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CommonService } from 'src/app/shared/service/common.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { RolesService } from 'src/app/shared/service/roles.service';
/* eslint-disable */

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  userProfile: any;
  isCustomerAdmin = false;
  isPresalesEngineer = false;
  url = '';
  isAdmin = false;
  isNetsyncioUser = false;
  isAccountManager = false;

  constructor(
    private authService: AuthService,
    private roleService: RolesService,
    private router: Router,
    private commonservice: CommonService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const currentRoute = route.routeConfig?.path;
    let returnVal: boolean = true;
    const claims = this.authService.getClaims();
    this.isAdmin = this.roleService.checkIfUserIsAdmin();
    this.isCustomerAdmin = this.roleService.checkIfUserIsCustomerAdmin();
    this.isNetsyncioUser = this.roleService.checkIfUserIsNormalUser();
    this.isPresalesEngineer = this.roleService.checkIfUserIsPresalesEngineer();
    this.isAccountManager = this.roleService.checkIfUserIsAccountManger();

    if (this.isAdmin) {
      return of(true);
    } else if (
      this.isCustomerAdmin ||
      this.isPresalesEngineer ||
      this.isAccountManager
    ) {
      this.url = window.location.pathname.substring(
        1,
        window.location.pathname.length
      );

      if (
        this.url == 'operate/savings-plans/list' ||
        this.url == 'operate/cloud-mapper' ||
        this.url == 'operate/cloud-mapper/accounts/list' ||
        this.url == 'operate/cloudtrail' ||
        this.url == 'measure/payments'
      ) {
        this.router.navigateByUrl('401');
        return of(false);
      } else {
        return of(true);
      }
    } else {
      return this.commonservice.accessListDataSubject.pipe(
        map((data: any) => {
          const list = data;

          this.url = window.location.pathname.substring(
            1,
            window.location.pathname.length
          );

          if (this.url === 'redirect' || this.url === '401') {
            returnVal = true;
          } else if (list.includes(this.url)) {
            returnVal = true;
          }

          // else {
          //   // this.router.navigateByUrl('401');
          //   returnVal = false;
          //   this.commonservice.errorMessage('Access Denied!');
          // }
          return returnVal;
        }),
        catchError((err) => {
          console.log(err);

          throw err;
        })
      );
    }
  }
}
