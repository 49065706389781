<div class="container">
  <div class="row">
    <div class="col-lg-12">
      <h1>Healthcare Landing Zone</h1>
      <h1>Case Study</h1>

      <h2>The Customer Challenge</h2>
      <p>
        A large Texas hospital system needed to ensure its newly provisioned
        Amazon Web Services (AWS) account structure was designed and deployed to
        meet the requirements of Health Insurance Portability and Accountability
        Act (HIPAA) while maintaining a cost optimized infrastructure to
        eliminate financial waste and ensure funding is focused on secure
        patient care services.
      </p>

      <h2>Governance Requirements</h2>
      <p>
        The hospital system’s Chief Information Security Officer (CISO) required
        the proper AWS organizational structure to ensure data authorization,
        integrity, and access was in place and managed before the hospital
        system placed their first production workload into the AWS Cloud. The
        CISO’s organization is moving toward a “code only” access model and
        would like access to the AWS console stickily limited and only used as a
        the exception if resources such as APIs, command lines, and
        Infrastructure as Code are not acceptable.
      </p>
      <p>
        Integration into the customer’s Azure AD SSO was also completed to allow
        the customer’s staff to use the same single sign-on authentication
        factors they already use in their On-Prem data center.
      </p>
      <p>
        In additional requirement included training of operational staff to
        ensure they possess the fundamental knowledge of AWS services needed to
        properly secure and operate a large AWS Cloud implementation.
      </p>

      <h2>Financial Management Requirements</h2>
      <p>
        The hospital’s financial management leadership had been hurt by an
        implementation of project in another cloud provider. This previous
        project ended up wasting the hospital’s valuable financial resources due
        to oversized virtual machines, oversized infrastructure, and poor
        availability of date into the real-time financial metrics of the
        departments using services from their previous cloud provider.
      </p>
      <p>
        Due to this previous experience, the customer insisted the ability to
        measure AWS expense at the Payer Account, Workload Account, and Workload
        Tagging levels. They also required the ability to cross-charge expenses
        at the Workload Account level to different departments if needed.
      </p>

      <h2>The Implemented Solution Set</h2>
      <h2>Governance Requirements Solution</h2>
      <p>
        Netsync worked with our customer to design and implement the AWS Landing
        Zone Accelerator for Healthcare solution. The LZA for Healthcare is an
        industry-specific deployment of the Landing Zone Accelerator on AWS
        solution purposely built to align with AWS best practices and in
        conformance with multiple, global compliance frameworks.
      </p>
      <p>
        Netsync deployed the LZA for Healthcare in coordination with AWS Control
        Tower and centralized audit logging to provide a comprehensive no-code
        solution across more than 35 AWS services and features to manage and
        govern their new multi-account environment.
      </p>
      <p>
        This solution also integrates the hospital’s third party firewall
        solutions using a traffic inspection Virtual Private Cloud (VPC) that
        allows the inspection of inbound and outbound network traffic using
        virtual firewalls the customer’s security organization is already
        trained to operate.
      </p>
      <img src="/assets/case-studies/health-care-landing-zone-1.jpg" />

      <h2>Financial Management Requirements Solution</h2>
      <p>
        Using the AWS Organizations Services built into the AWS Landing Zone
        Accelerator for Healthcare solution brought several efficiencies to
        allow our customer to quickly monitor their AWS spend. Solutions such as
        centralized cost and usage reporting allow the hospital to solve their
        requirements for Payer and Workload level cost reporting and
        Organizational Tagging Policies assisted in standardizing tagging across
        resources throughout the customer’s AWS organization.
      </p>
      <p>
        Netsync also provided a pre-built business intelligence platform call
        NetsyncIO that allows the customer to quickly under this information
        without the need to build custom dashboards or provide end user access
        to the AWS colnsole. The customer can easily see the spend of their
        entire organization at one time or filter the resulting data based on
        any field available to them in the Cost and Usage Report. This includes
        Linked (Workload) Accounts, AWS Service Types, AWS Regions, Custom
        Tagging and much more.
      </p>
      <p>
        The following screen shot is an example of the customer’s top 15
        workload accounts
      </p>
      <img src="/assets/case-studies/health-care-landing-zone-2.jpg" />
      <p>
        The following screen shot is an example of building a filter based on
        AWS tagging
      </p>
      <img src="/assets/case-studies/health-care-landing-zone-3.jpg" />

      <h2>The Result</h2>
      <p>
        Netsync worked with the Hospital System to dive deep into their
        potential workloads and gain a clear understanding of their current
        environment including network, security, workloads, and staff training.
        This allowed Netsync to work closely with the customer to develop a
        detailed deployment plan for their new AWS Healthcare Landing Zone.
      </p>
      <p>
        Netsync implemented this AWS Landing Zone using Terraform automation and
        preserved this implementation using standard infrastructure as code
        pipelines and repositories already available within the customer’s
        environment. Netsync also coordinated the training and development of 15
        staff members in the areas of AWS Networking (VPC), Control Tower, Guard
        Rails, and audit logging.
      </p>
      <p>
        Netsync also implemented financial cost management that met the needs of
        an organization that is going to continue to grow quickly but needs to
        maintain a detailed level of cost management to ensure their funding is
        used in the most economical way possible.
      </p>
      <p>
        All of this work resulted in a safe, secure, and cost optimized AWS
        Landing Zone implementation allowing the customer to deploy new
        workloads using a know deployment pattern producing infrastructure in
        compliance with HIPAA and other programs.
      </p>

      <h2>About Netsync</h2>
      <p>
        Based in Houston, TX, Netsync uses a blended approach that is both
        consultative and collaborative. We work with our clients to assess their
        needs, architect innovative technology solutions to meet those needs,
        and offer 24/7 access to our team of engineering experts who exceed
        clients’ expectations. This approach has earned Netsync numerous
        customer service excellence awards and recognition as a progressive
        partner that introduces the newest, best-of-breed products and solutions
        to clients.
      </p>
    </div>
  </div>
</div>
