/* netsyncio tour configrations  */

const url = '/deploy/cloud-formation-stack';

export const STEPS_BUTTONS = {
  back: {
    classes: 'back-button',
    secondary: true,
    text: 'Back',
    type: 'back',
  },
  cancel: {
    classes: 'cancel-button',
    secondary: true,
    text: 'Exit',
    type: 'cancel',
  },
  next: {
    classes: 'next-button',
    text: 'Next',
    type: 'next',
  },
};

export const defaultStepOptions = {
  classes: 'shepherd-theme-custom',
  scrollTo: true,
  allowSkipSteps: true,
  cancelIcon: {
    enabled: true,
  },
};

export const steps: any[] = [
  {
    attachTo: {
      // element: 'h1',
      on: 'top',
    },
    buttons: [STEPS_BUTTONS.cancel, STEPS_BUTTONS.next],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'intro',
    title: 'Welcome to NetsyncIO',
    text: `It appears you are new to the platform and need to on board your first AWS Billing Account.`,
  },

  {
    attachTo: {
      // element: 'h2',
      on: 'top',
    },
    buttons: [
      {
        text: 'End Tour',
        classes: 'next-button',
        action: () => {
          window.location.href = url;
        },
      },
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'usage',
    title: 'Deploy NetsyncIO',
    text: `Let's go to the "Deploy NetsyncIO" Automation to get started!`,
    cancelIcon: {
      enabled: true,
    },
  },
  {
    attachTo: {
      element: 'a',
      on: 'top',
    },
    buttons: [STEPS_BUTTONS.cancel, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    text: `Step with no title and no exit (x button) at right`,
    cancelIcon: {
      enabled: false,
    },
  },
];
