<mat-card [class.featured]="featured">
  <h1 *ngIf="header">{{ title }}</h1>
  <img *ngIf="imgSrc" mat-card-image [src]="imgSrc" alt="news-articles" />
  <mat-card-content>
    <div *ngIf="!header" class="content-header">
      <small class="title">{{ title }}</small>
      <small class="time">{{ time }}</small>
    </div>
    <p [class.header]="header">
      {{ description }}
    </p>
    <a *ngIf="!header" [href]="url" target="_blank"
      >See full article
      <span class="material-icons"> open_in_new </span>
    </a>
  </mat-card-content>
</mat-card>
