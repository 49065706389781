<!-- WHY DID WE CREATE NETSYNC.IO?-->

<section id="netsync-section">
  <div class="container">
    <div class="section-header">
      <h3 class="section-title">Why did we create Netsync.IO?</h3>
      <span class="line"></span>
    </div>
    <div class="feature f1">
      <div class="text">
        <div class="desc">
          <p>
            Netsync receives continuous customer feedback telling us technology
            operation, integration, and management needs to be easier.
          </p>

          <p class="netsync-desc">
            A great example of customer frustration is trying to integrate
            technology platforms, from different OEMs, with a cloud vendor or
            management platform. The issue is normally that the integrations do
            not exist or have limited functionality. To solve the customer’s
            challenge, the vendor always has a product with the similar
            functionality to replace existing systems. If the customer insists
            on integrating with their existing systems, then the vendor will
            usually reply with ‘No worries, we have an API for that.’ This is
            the point most customers stop and say if we wanted to hire
            programmers and create hundreds of API integrations, we would not
            need your systems.
          </p>

          <p>
            Netsync has seen this scenario play out time and time again, and
            from this frustration Netsync.IO was born.
          </p>
        </div>
      </div>

      <div class="image">
        <img
          class="feature-img"
          src="/assets/icons/operate_screens.png"
          alt="operate-charts"
        />
      </div>
    </div>
  </div>
</section>
<section id="netsync-api">
  <div class="container">
    <div class="feature">
      <div class="image">
        <img
          class="feature-img"
          src="/assets/icons/deploy_screens.png"
          alt="graph"
        />
      </div>

      <div class="text">
        <h3 class="title">Netsync has programmers, and we love APIs</h3>
        <div class="desc">
          <p>
            Netsync.IO is taking the APIs your hardware and software vendors
            sold you and enabling them to their fullest potential. We want
            customers to gain the most out of their investments by creating the
            software to enable these integrations and providing an ecosystem of
            tools that allow you to consume new services. This is only the
            beginning, and we are adding new features every month. If you do not
            see something you want, let us know, so we can discuss where it is
            on our development roadmap.
          </p>
          <p>
            The cloud is BIG. Really big! Customers need a partner that can help
            them in their journey to navigate the cloud; Netsync has been here,
            listening to customers, for almost 20 years, and we decided to
            simplify things—
          </p>
        </div>
      </div>
    </div>

    <div id="welcome-text">
      <h3>
        Welcome to
        <a routerLink="'/'"><img src="/assets/icons/logo.svg" alt="" /></a>
      </h3>
    </div>
  </div>
</section>

<!-- ./ WHY DID WE CREATE NETSYNC.IO?-->

<!-- Measure section-->

<section id="measure-section">
  <div class="container">
    <div class="feature">
      <div class="text">
        <h3 class="title">Measure</h3>
        <div class="desc">
          <p>
            A primary customer challenge is cost and usage management. Many
            technology vendors provide tools such as widgets and data feeds to
            build management dashboards, but leave it up to the customer to
            build them for production. Netsync.IO provides most common cost and
            usage dashboards pre-built ready to go.
          </p>
          <p>
            We are always listening to our customers to understand the new
            service specific dashboards they need to better understand their
            environments. Customers do not need to hire a team of specialists to
            create your business intelligence dashboards because Netsync.IO is
            working with customers to scope and develop new analysis and make
            them available to our customers.
          </p>
        </div>
      </div>
      <div class="image">
        <img
          class="feature-img"
          src="/assets/icons/measure_screens.png"
          alt="graph"
        />
      </div>
    </div>
  </div>
</section>
<!-- ./Measure section -->

<!-- Deploy section-->
<section id="deploy-section">
  <div class="container">
    <div class="feature">
      <div class="image">
        <img
          class="feature-img"
          src="/assets/icons/deploy_screens.png"
          alt="graph"
        />
      </div>

      <div class="text">
        <h3 class="title">Deploy</h3>
        <div class="desc">
          <p>
            Clouds have hundreds of services and getting started can be a
            daunting task. Netsync.IO creates and maintains a curated list of
            automations based on their direct customer feedback. Netsync.IO
            automations solve real world customer workload challenges allowing
            customers to get work completed rather than spending months learning
            a new vendor’s ecosystem.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- ./ Depoly section-->

<!-- operate section-->

<section id="operate-section">
  <div class="container">
    <div class="feature">
      <div class="text">
        <h3 class="title">Operate</h3>
        <div class="desc">
          <p>
            Clouds need best practices and Netsync.IO can provide them. Customer
            accounts are directly linked to AWS best practices to make checking
            the health of your cloud account easy. Netsync.IO does not just tell
            you what might need attention, but we also give you advice on the
            exact the AWS infrastructure needing attention and recommend actions
            to improve any challenges that may exist.
          </p>
        </div>
      </div>

      <div class="image">
        <img
          class="feature-img"
          src="/assets/icons/operate_screens.png"
          alt="operate-charts"
        />
      </div>
    </div>
  </div>
</section>

<!-- ./ operate section -->

<!-- Train section-->

<section id="train-section">
  <div class="container">
    <div class="feature">
      <div class="image">
        <img
          class="feature-img"
          src="/assets/icons/trains_screens.png"
          alt="train-screen"
        />
      </div>
      <div class="text">
        <h3 class="title">Train</h3>
        <div class="desc">
          <p>
            Cloud services are constantly growing and improving, and your teams
            need training. Did you know AWS offers high quality training on
            dozens of different services for free? Most customers know the
            training is available but find it difficult to locate on the AWS
            website. Netsync.IO built a search page allowing our customers to
            quickly find all the no cost AWS training and go directly to it. If
            your teams need more advanced training, customers can subscribe to a
            leading cloud training company and have their training catalog
            integrated, searchable, and launchable from a single interface.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- ./Train section-->
<!-- Support Section-->
<section id="support-section">
  <div class="container">
    <div class="feature">
      <div class="text support-text">
        <h3 class="title">Support</h3>
        <div class="desc">
          <p>
            Customers need support and Netsync.IO can provide it. Did you know
            Netsync offers 24/7 support? Netsync operates two geo-separated
            support centers and offers support to customers in all areas of
            Cloud, Collaboration, Networking, Security, and much more. Customers
            with the proper level of support from Netsync.IO can use local
            resources to support their cloud journey and ensure your
            organization is covered no matter what happens.
          </p>
          <p>
            This is unique to the industry because we can support your
            technology infrastructure, both on-site and in the cloud, using an
            integrated support approach. Netsync knows customer workloads do not
            stop at the firewall and they need support from a company with
            experience in the physical and the virtual data world.
          </p>
          <p>
            Netsync.IO was born to serve customers. Our founders have worked in
            the technology industry for more than 20 years building
            infrastructure and systems to support organizations with hundreds of
            thousands of end users in multiple States in the United States and
            multiple countries abroad.
          </p>
          <p>
            If you are already a Netsync customer, you can access Netsync.IO by
            contacting your account manager. If you are not a current customer,
            please drop us a note or give us a call. We want to discuss our
            services with you.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- ./support-section-->
