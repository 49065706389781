<mat-list
  [class.collapsed]="!!commonService.clickHamburgerHelp.value"
  *ngIf="isValidLogin()"
>
  <button
    id="menu-toggler-help"
    class="help-icon"
    mat-icon-button
    disableRipple
    *ngIf="commonService.clickHamburgerHelp.value"
    (click)="commonService.showHelpSidebar('f2315d76-2820-465c-9896-825521861f8d')"
  >
    <img src="assets/icons/help-icon-sidebar.svg" />
  </button>
  <button
    (click)="closeHelp()"
    class="close"
    mat-icon-button
    disableRipple
    *ngIf="!commonService.clickHamburgerHelp.value"
  >
    X
  </button>
  <div class="help-content" *ngIf="!commonService.clickHamburgerHelp.value">
    <div *ngIf="commonService?.selectedHelpItem">
      <h1>{{ commonService?.selectedHelpItem.title }}</h1>
      <div class="description" *ngIf="helpDescriptionData?.length > 0">
          <p *ngFor="let helpDesc of helpDescriptionData">
            <span *ngIf="helpDesc?.trim()?.length >0">{{helpDesc}}.</span> 
          </p>
      </div>
      <!-- <span>
        {{ commonService?.selectedHelpItem.description }}
      </span> -->
    </div>
    <p *ngIf="!loading && !commonService?.selectedHelpItem" class="error"> {{helpSystemErrorMessage}}</p>
    <mat-spinner  *ngIf="loading && !commonService?.selectedHelpItem"     
      class="spinner"
      diameter="20"
    ></mat-spinner>
  </div>
</mat-list>
