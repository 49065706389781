import { CommonService } from './../../service/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { Component, Inject, OnInit } from '@angular/core';
import { CloudServiceAgreementService } from '../../service/cloud-service-agreement.service';
import { SessionInformationService } from '../../service/session-information.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
/* eslint-disable */
@Component({
  selector: 'nio-cloud-service-agreement',
  templateUrl: './cloud-service-agreement.component.html',
  styleUrls: ['./cloud-service-agreement.component.scss'],
})
export class CloudServiceAgreementComponent implements OnInit {
  claims: any;
  cloudServiceAgreementForm: FormGroup;
  loading = false;
  saving = false;
  companyName;

  constructor(
    public dialogRef: MatDialogRef<CloudServiceAgreementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cloudAgreementService: CloudServiceAgreementService,
    private authService: AuthService,
    private sessionInfo: SessionInformationService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.claims = this.authService.getClaims();
    this.getCompanyName();
  }

  initForm() {
    this.cloudServiceAgreementForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      title: new FormControl('', [Validators.required]),
      customer_id: new FormControl('', [Validators.required]),
      browser: new FormControl('', [Validators.required]),
      operating_system: new FormControl('', [Validators.required]),
      email_address: new FormControl('', [Validators.required]),
      email_verification_status: new FormControl('', [Validators.required]),
    });
  }

  getCompanyName() {
    this.cloudAgreementService
      .getcompanyNameForAgreement(this.claims?.customer_id)
      .subscribe((data) => {
        if (data) {
          this.companyName = data;
        }
      });
  }

  acceptCloudAgreement() {
    this.loading = true;
    this.saving = true;
    const browser = this.sessionInfo.getBrowserInfo();

    const requestData = {
      first_name: this.claims?.firstName,
      last_name: this.claims?.lastName,
      title: this.claims?.nickname,
      customer_id: this.claims?.customer_id,
      email_address: this.claims.email,
      email_verification_status: this.claims.email_verified,
      browser: browser.name,
      operating_system: this.sessionInfo.getOperatingSystem(),
    };
    this.cloudAgreementService
      .acceptCloudServiceAgreement(requestData)
      .subscribe(
        (data: any) => {
          if (data === 'Success') {
            this.loading = false;
            this.saving = false;
            this.dialogRef.close();
          } else {
            this.loading = false;
            this.saving = false;
            this.commonService.errorMessage(
              'Something went wrong, Please try again later.'
            );
          }
        },
        () => {
          this.loading = false;
          this.saving = false;
          this.commonService.errorMessage(
            'Something went wrong, Please try again later.'
          );
        }
      );
  }
}
