<main class="wrap">
  <section class="container cdk-global-scrollblock">
    <div class="container__heading">
      <h2 mat-dialog-title>Cloud Services Agreement</h2>
      <div class="container__heading_image">
        <img
          class="netsync__logo"
          src="assets/icons/netsync.svg"
          alt="NETSYNC.COM"
        />
      </div>
    </div>
    <div mat-dialog-content class="container__content">
      <p>
        This Software as a Service (SaaS) Agreement (“Agreement”) is between
        Netsync Network Solutions (“Netsync”) and
        <span class="custome__name">{{ companyName }}</span> (“Customer”) in
        order for Netsync to facilitate the use of all Infrastructure as a
        Service or Platform as a Service (“Services”) offered by Amazon Web
        Services (AWS) by Customer. By signing (Electronically or Manually) this
        Agreement or an Order for the Services, or by using the Services,
        Customer accepts this Agreement.
      </p>

      <h3>Scope of Agreement.</h3>
      <p>
        This Agreement governs Customer access to and use of the Services.
        Netsync agrees to facilitate the Services for the Term specified in an
        Order.
      </p>
      <h4>Order(s).</h4>
      <p>
        By signing this Agreement, Customer will have the ability to access
        Services offered by AWS. Once Customer has access to AWS, they will have
        the ability to process and place Order(s) for AWS Services. These
        Order(s) are incorporated into this Agreement by this reference. An
        Order is effective when Customer signs or accept Services (whichever
        happens first). Customer may need to provide additional information to
        register for and/or use certain Services. Customer warrants that the
        information provided during the registration process is accurate.
      </p>
      <h4>Changes to Services.</h4>
      <p>
        Customer will have the option to add, change, or cancel Services. The
        Services may be enhanced and/or changed, and the features of the
        Services may change, so long as enhancements and/or changes do not
        materially reduce the core functionality of the Services.
      </p>
      <h4>Fees and Payment for the Services.</h4>
      <ol>
        <li>
          <h4>Payment Terms.</h4>
          <p>
            The fees for the Services are outlined to Customer as they access
            AWS Services and place Orders. Customer agrees to pay Netsync these
            amounts or charges as Customer incurs them. Customer will pay
            invoices for the Services within 30 days of the invoice.
          </p>
        </li>
        <li>
          <h4>Fee Disputes.</h4>
          <p>
            Customer may withhold amounts that Customer reasonably and in good
            faith disputes as to the amounts owed. Customer will pay any
            undisputed fees. If Customer withholds any payment due to a dispute,
            Customer must notify Netsync in writing of any disputed fees within
            15 days of the invoice date and provide Netsync with written details
            about why Customer disputes the invoice. After Netsync receives
            notice of the dispute, Netsync will work with Customer in good faith
            to resolve the dispute.
          </p>
        </li>
        <li>
          <h4>Taxes</h4>
          <p>
            Customer shall pay all sales, value added, general standard, and
            similar taxes; levies; duty; or charges imposed by any governmental
            authority, related to or arising from the use of the Services.
            Netsync reserves the right to gross up the price for the Services in
            any invoice, if a withholding prevents Netsync from receiving the
            amount specified in such invoice.
          </p>
        </li>
      </ol>
      <h3>Term and Termination.</h3>
      <ol>
        <li>
          <h4>Term and Termination of Orders.</h4>
          <p>
            An Order may be terminated at any time by Customer in accordance
            with AWS standards.
          </p>
        </li>

        <li>
          <h4>Term and Termination of Agreement.</h4>
          <p>
            Either party may terminate this Agreement upon 60 days’ prior
            written notice to the other party, with or without cause.
            Termination of this Agreement will terminate the Customer’s access
            to AWS Services and will terminate all active Orders.
          </p>
        </li>
      </ol>
      <div class="load" *ngIf="loading">
        <mat-spinner diameter="40" class="btn-spinner"> </mat-spinner>

        <p class="loading-text">Loading...</p>
      </div>
    </div>
    <div class="container__nav">
      <small
        >By clicking 'Accept' you are agreeing to our terms and
        conditions.</small
      >
      <button type="submit" mat-flat-button (click)="acceptCloudAgreement()">
        Accept
      </button>
    </div>
  </section>
</main>
