import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'nio-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  currentApplicationVersion = environment.appVersion;

  constructor() {}
}
