import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import {
  carouselOptions,
  caseStudiesCarouselOptions,
} from '../../shared/app.constant';

/* eslint-disable */

@Component({
  selector: 'nio-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingComponent implements OnInit, OnDestroy {
  bodyEL = document.getElementsByTagName('body')[0];
  carouselOptions = carouselOptions;
  caseStudiesCarouselOptions = caseStudiesCarouselOptions;
  header = {
    title: 'Keep Yourself on the Cutting Edge of Information',
    description:
      'The best place to get information directly from the industry’s leading technologists in through the Netsync Blog. It’s the only place to get the inside scoop on what’s happening in the industry, from our engineers to you. From Optical Transport and Data Center, to Cybersecurity and Managed Services, you’ll find our experts talking about all of it.',
  };

  newsItems = [
    {
      title:
        'Sundown ISD and Netsync Deliver Adobe Applications to Students via ChromeOS using Amazon AppStream 2.0',
      time: 'March 4,2021',
      imgSrc:
        'https://www.netsync.com/wp-content/uploads/2021/03/appstream20small-1.jpg',
      url: 'https://www.netsync.com/story/sundown-isd-and-netsync-deliver-adobe-applications-to-students-via-chromeos-using-amazon-appstream-2-0/',
      description:
        'Netsync and AWS Ensure the Delivery of High-Performance Applications to All Students',
    },

    {
      title:
        'Orange Unified School District and Netsync Deliver Software Applications to Remote Students',
      time: 'December 2, 2020',
      imgSrc:
        'https://www.netsync.com/wp-content/uploads/2020/12/appstream-adobe2.jpg',
      url: 'https://www.netsync.com/orange-unified-school-district-and-netsync-deliver-software-applications-to-remote-students/',
      description:
        'Close Collaboration Aides Thousands of Students Working from Home The Challenge The COVID-19 pandemic is challenging school districts to adopt new methods of empowering teachers to deliver remote instruction and ensure students continue to learn as if they were still in the classroom. One tool available to educators is to ensure their students have access…',
    },
    {
      title:
        'Netsync Receives Prestigious SLED Award During Cisco 2020 Partner Summit',
      time: 'October 30, 2020',
      imgSrc:
        'https://www.netsync.com/wp-content/uploads/2020/10/netsync-3-awards-1.jpg',
      url: 'https://www.netsync.com/netsync-receives-prestigious-sled-award-during-cisco-2020-partner-summit/',
      description:
        'Netsync Recognized for Its Public Sector Leadership HOUSTON, TX, October 29, 2020 – Cisco’s digital version of its annual partner summit this year honored Netsync with its SLED Partner of the Year award for the US Public Sector recognizing its innovation, leadership and best practice as a Cisco business partner. The Cisco Partner Summit Digital…',
    },
    {
      title: 'NEED FOR SPEED (OF DEPLOYMENT)',
      time: 'November 10, 2020',
      imgSrc:
        'https://www.netsync.com/wp-content/uploads/2020/11/Netsync-Beauty1.jpg',
      url: 'https://www.netsync.com/need-for-speed-of-deployment/',
      description:
        'Netsync is helping organizations focus on agility and automation to facilitate faster time to value by deploying critical cloud services. Bjørn Bergqvist, Senior Product Marketing Manager Netsync quickly added a new cloud service in 2020 when Milestone launched XProtect on Amazon Web Services (AWS). The video management software offers the flexibility to scale infrastructure resources…',
    },
    {
      title:
        'Inc. Magazine to Recognize Its Annual List of America’s Fastest-Growing Private Companies',
      time: 'September 29, 2020',
      imgSrc: 'https://www.netsync.com/wp-content/uploads/2020/09/inc.jpg',
      url: 'https://www.netsync.com/inc-magazine-to-recognize-its-annual-list-of-americas-fastest-growing-private-companies/',
      description:
        'Netsync recognized for its impressive 70% growth rate  HOUSTON, October, 2020 – Inc. magazine will be recognizing Netsync’s addition to its annual list of the nation’s fastest-growing private companies later this month during its annual event acknowledging the Inc. 5000. The list represents a unique look at the most successful companies within the American economy’s…',
    },
    {
      title: 'Netsync and Dell Power Hour!',
      time: 'October 9, 2020',
      imgSrc:
        'https://www.netsync.com/wp-content/uploads/2020/10/powerstore.jpg',
      url: 'https://www.netsync.com/netsync-and-dell-power-hour/',
      description:
        'We’ve entered the data era, where organizations must capitalize on the myriad of data sources available in order to achieve competitive advantage. But how can IT organizations effectively address their data needs and improve agility to enable digital transformation?',
    },
  ];

  netsyncItems = [
    {
      imgSrc:
        'https://www.netsync.com/wp-content/uploads/2021/03/appstream20small-1.jpg',

      url: 'https://www.netsync.com/story/sundown-isd-and-netsync-deliver-adobe-applications-to-students-via-chromeos-using-amazon-appstream-2-0/',
      title:
        'Sundown ISD and Netsync Deliver Adobe Applications to Students via ChromeOS using Amazon AppStream 2.0',
    },
    {
      imgSrc:
        'https://www.netsync.com/wp-content/uploads/2020/12/appstream-adobe2.jpg',
      url: 'https://www.netsync.com/orange-unified-school-district-and-netsync-deliver-software-applications-to-remote-students/',

      title:
        'Orange Unified School District and Netsync Deliver Software Applications to Remote Students',
    },
    {
      imgSrc: 'https://www.netsync.com/wp-content/uploads/2020/09/inc.jpg',
      title:
        'Inc. Magazine to Recognize Its Annual List of America’s Fastest-Growing Private Companies',
      url: 'https://www.netsync.com/inc-magazine-to-recognize-its-annual-list-of-americas-fastest-growing-private-companies/',
    },
  ];

  caseStudies = [
    {
      imgSrc: '/assets/case-studies/large-texas-city-1.jpg',
      url: '/case-studies/large-texas-city',
      title: 'Large City Security Implementation',
    },
    {
      imgSrc: '/assets/case-studies/health-care-landing-zone-1.jpg',
      url: '/case-studies/health-care-landing-zone',
      title: 'Healthcare Landing Zone',
    },
  ];

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    this.bodyEL.classList.add('no-sidenav');
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/operate']);
    }
  }

  scroll(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  ngOnDestroy(): void {
    this.bodyEL.classList.remove('no-sidenav');
  }
}
