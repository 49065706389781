import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpCacheService } from './http-cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cahceService: HttpCacheService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method !== 'GET' || req.params.has('cache')) {
      this.cahceService.invalidateUrl(req.urlWithParams);
      return next.handle(req);
    }

    const cachedResponse: HttpResponse<any> = this.cahceService.get(
      req.urlWithParams
    );

    if (cachedResponse && req.urlWithParams !== 'aws-inventory-tab/filters') {
      return of(cachedResponse);
    }

    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.cahceService.put(req.urlWithParams, event);
        }
      })
    );
  }
}
