<mat-toolbar class="custom-navbar">
  <div class="container">
    <button
      mat-icon-button
      class="humburger"
      *ngIf="isUserLoggedIn()"
      (click)="toggle()"
    >
      <img
        *ngIf="!isCollapsed"
        src="/assets/icons/left Arrow.svg"
        alt="left-arrow icon"
      />
      <img
        *ngIf="isCollapsed"
        src="/assets/icons/Right Arrow.svg"
        alt="right-arrow icon"
      />
    </button>

    <div class="test">
      <a routerLink="/" class="navbar-brand">
        <img src="/assets/icons/logo.svg" class="logo" alt="netsynico logo" />
      </a>

      <div class="username-wrapper" *ngIf="!isUserLoggedIn()">
        <button
          mat-icon-button
          disableRipple="true"
          [matMenuTriggerFor]="signin_menu"
        >
          <span class="link"
            >Sign in<mat-icon class="link" style="margin-left: 0.2rem"
              >login</mat-icon
            ></span
          >
        </button>
        <mat-menu #signin_menu="matMenu">
          <div>
            <a class="link" (click)="onLogin('auth0')" routerLink=""
              >Customer</a
            >
          </div>
          <div>
            <a class="link" (click)="onLogin('azure')" routerLink=""
              >Employee</a
            >
          </div>
        </mat-menu>
      </div>
    </div>

    <div class="username-wrapper" *ngIf="isUserLoggedIn()">
      <button
        class="username"
        mat-icon-button
        disableRipple
        [matMenuTriggerFor]="menu"
      >
        <img src="/assets/icons/profile-icon.svg" alt="profile-icon" />
        <span class="given-name">{{ getClaims()?.given_name }}</span>
        <i class="material-icons"> expand_more </i>
      </button>
      <!-- <small [class.blur-text]="blurHelper.enableBlur">{{ selectedAccount?.Name }}</small> -->
      <mat-menu #menu="matMenu">
        <div class="user-info">
          <ng-container *ngIf="getClaims()?.name || isLoading">
            <small> Username:</small>
            <span class="role-name" [class.blur-text]="blurHelper.enableBlur"
              >{{ getClaims()?.name | titlecase }}
              <img
                class="pl-2"
                id="auth0-azure-logo"
                [src]="getImage()"
                alt="logo"
              />
            </span>
          </ng-container>
          <ng-container *ngIf="getRoles() || isLoading">
            <small> Current User Roles : </small>
            <span class="role-name" [class.blur-text]="blurHelper.enableBlur">{{
              getRoles()
            }}</span>
          </ng-container>
          <mat-spinner
            *ngIf="isLoading"
            class="spinner"
            diameter="15"
          ></mat-spinner>

          <mat-spinner
            *ngIf="isLoading"
            class="spinner"
            diameter="15"
          ></mat-spinner>
          <ng-container
            *ngIf="
              (selectedAccount?.Name || isLoading) &&
              (isAdmin ||
                isCustomerAdmin ||
                isPresalesEngineer ||
                isAccountManager)
            "
          >
            <small> Active AWS Account:</small>
            <span class="role-name" [class.blur-text]="blurHelper.enableBlur">
              {{
                selectedAccount?.Name === "null"
                  ? "No Account Found"
                  : selectedAccount?.Name
              }}
            </span>
          </ng-container>

          <ng-container
            *ngIf="(selectedAccount?.Name || isLoading) && isNetsyncioUser"
          >
            <small> Active AWS Account:</small>
            <span class="role-name" [class.blur-text]="blurHelper.enableBlur">
              {{
                selectedAccount?.Name === "null"
                  ? "No Account Found"
                  : selectedAccount?.Name
              }}
            </span>
          </ng-container>
          <mat-spinner
            *ngIf="isLoading"
            class="spinner"
            diameter="15"
          ></mat-spinner>
        </div>

        <div class="accounts-list">
          <ul class="accounts-names">
            <li>AWS Accounts :</li>
            <mat-spinner
              *ngIf="isLoading"
              class="spinner spinner-margin"
              diameter="20"
            ></mat-spinner>
            <ng-container
              *ngIf="
                (isLoading || accounts?.length) &&
                (isAdmin ||
                  isCustomerAdmin ||
                  isPresalesEngineer ||
                  isAccountManager)
              "
            >
              <li
                *ngFor="let account of accounts | slice : 0 : 5"
                [class.blur-text]="blurHelper.enableBlur"
                [class.selected]="account.Id === selectedAccount?.Id"
                (click)="accountChange($event, account)"
              >
                {{ account?.Name }}
              </li>
              <!-- this for netsyncio user -->
            </ng-container>
            <ng-container
              *ngIf="(isLoading || accounts?.length) && isNetsyncioUser"
            >
              <li
                *ngFor="let account of accounts | slice : 0 : 6"
                [class.blur-text]="blurHelper.enableBlur"
                [class.selected]="account.Id === selectedAccount?.Id"
                (click)="accountChange($event, account)"
              >
                {{ account?.Name }}
              </li>
            </ng-container>
          </ul>
          <p *ngIf="!isLoading && accounts?.length === 0">
            <!-- You have no NetsyncIO managed accounts. -->
            <a id="new-account" routerLink="/deploy">Create New AWS Account</a>
          </p>
          <a
            class="see-all"
            *ngIf="
              (isLoading || accounts?.length) &&
              (isAdmin ||
                isCustomerAdmin ||
                isPresalesEngineer ||
                isAccountManager)
            "
            routerLink="/operate/account-list"
            >See All
            <span class="material-icons see-all"> arrow_right_alt </span>
          </a>
        </div>

        <!-- <div *ngIf="!isLoading && accounts.length === 0">
          <p>
            You have no NetsyncIO managed accounts.
            <a id="new-account" routerLink="/deploy">Create New AWS Account</a>
          </p>
        </div> -->

        <div class="profile">
          <a routerLink="/profile"> MY Profile </a>
        </div>

        <div
          class="item account-managers"
          *ngIf="!isLoading && accounts?.length > 0"
          (click)="openAccountManagersModal()"
        >
          <p>Account Manager Information</p>
        </div>
        <div *ngIf="isAdmin" class="item">
          <mat-slide-toggle
            (click)="$event.stopPropagation()"
            (change)="demoModeHandler($event)"
            [formControl]="demoModeControl"
            disableRipple
            >Demo Mode</mat-slide-toggle
          >
        </div>
        <div class="item">
          <mat-slide-toggle
            [checked]="themeService.isDarkThemeSelected"
            (change)="toggleDarkMode($event)"
            disableRipple
          >
            Dark Mode
          </mat-slide-toggle>
        </div>
        <button (click)="onLogoutClick()" id="logout" mat-menu-item>
          Logout
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
