import { Component } from '@angular/core';
/* eslint-disable */

@Component({
  selector: 'nio-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent {
  newsItems = [
    {
      title:
        'Sundown ISD and Netsync Deliver Adobe Applications to Students via ChromeOS using Amazon AppStream 2.0',

      imgSrc:
        'https://www.netsync.com/wp-content/uploads/2021/03/appstream20small-1.jpg',

      url: 'https://www.netsync.com/story/sundown-isd-and-netsync-deliver-adobe-applications-to-students-via-chromeos-using-amazon-appstream-2-0/',
    },

    {
      title:
        'Orange Unified School District and Netsync Deliver Software Applications to Remote Students',

      imgSrc:
        'https://www.netsync.com/wp-content/uploads/2020/12/appstream-adobe2.jpg',

      url: 'https://www.netsync.com/orange-unified-school-district-and-netsync-deliver-software-applications-to-remote-students/',
    },

    {
      title:
        'Netsync Receives Prestigious SLED Award During Cisco 2020 Partner Summit',

      imgSrc:
        'https://www.netsync.com/wp-content/uploads/2020/10/netsync-3-awards-1.jpg',

      url: 'https://www.netsync.com/netsync-receives-prestigious-sled-award-during-cisco-2020-partner-summit/',
    },

    {
      title:
        'Inc. Magazine to Recognize Its Annual List of America’s Fastest-Growing Private Companies',

      imgSrc: 'https://www.netsync.com/wp-content/uploads/2020/09/inc.jpg',

      url: 'https://www.netsync.com/inc-magazine-to-recognize-its-annual-list-of-americas-fastest-growing-private-companies/',
    },
  ];
  constructor() {}
}
