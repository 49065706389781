import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'nio-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.scss'],
})
export class MappingComponent implements OnInit {
  loading = true;

  constructor(private router: Router, private commonService: CommonService) {}
  ngOnInit(): void {
    console.log('@Mapping Component >> ');
    setTimeout(() => {
      this.getUrlMappingMainURL();
    }, 500);
  }
  getUrlMappingMainURL() {
    console.log('@window.location >> ', window.location.href);
    const currentUrl = window.location.href;
    this.commonService.getUrlMappingMainURL(currentUrl).subscribe(
      (data: any) => {
        // console.log('@getUrlMappingMainURL() >> data >> ', data);
        this.loading = false;
        if (data?.urlMappings?.length > 0) {
          window.open(data?.urlMappings[0].mainUrl, '_self');
        } else {
          this.router.navigateByUrl('/measure');
        }
        // window.open(data?.urlMappings[0].mainUrl, '_self');
        // console.log('@generateQrCode >> ', data);
      },
      (err) => {
        this.loading = false;
        if (err?.error?.apiError?.errorMessage) {
          this.commonService.errorMessage(err?.error?.apiError?.errorMessage);
        } else {
          this.commonService.errorMessage(
            'Something went wrong while getting Main URL, Please try again later.'
          );
        }
        this.router.navigateByUrl('/measure');
      }
    );
  }
}
