import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { IDPType, authConfig } from './auth.config';
import { environment } from 'src/environments/environment';

type LoggedInUser = {
  role: string[];
  firstName: string;
  lastName: string;
  phone: string;
  customer_id: string;
  child_accounts: string;
  customer_name: string;
  accountManagerId: string;
  defaultDisplayAccount: string;
  defaultMode: string;
  defaultPage: string;
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  email: string;
  email_verified: boolean;
  idp: IDPType;
  id: string;
};
@Injectable({
  providedIn: 'root',
})

/**
 * The AuthService class is responsible for handling user authentication and authorization.
 * It provides methods for identifying the authenticated IDP type, logging in existing users, and verifying user credentials.
 * The class also includes methods for getting claims and getting authentication tokens.
 */
export class AuthService {
  private loggedInUser: LoggedInUser;

  /**
   * @param private OAuthService
   */
  constructor(private oauthService: OAuthService) {
    this.loggedInUser = null;
  }

  /**
   * Configures the OAuth service to authenticate to IDP based on the selected IDPType.
   * This is called by the login and should not be called directly.
   *
   * @param idp:IDPType - The identity provider for the user. This must be one of the IDP types defined in IDPType.
   */
  async configureAuth(idp: IDPType): Promise<void> {
    this.oauthService.configure(authConfig(idp));
    this.oauthService.setStorage(sessionStorage);
    await this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.oauthService.setupAutomaticSilentRefresh();
  }

  /**
   * Called when the session is loaded. This is the place where we set OAuth configuration on application load.
   */
  async onLoad(): Promise<void> {
    const { iss } =
      JSON.parse(sessionStorage.getItem('id_token_claims_obj')) || {};
    // Configure the authentication if the given iss is on of an IDPTypes.
    if (iss && (this.isAuth0IDP(iss) || this.isAzureIDP(iss))) {
      await this.configureAuth(
        this.isAuth0IDP(iss) ? IDPType.auth0 : IDPType.azure
      );
    }
  }

  /**
   * Starts the login flow for Auth0.
   */
    async loginWithAuth0(): Promise<void> {
      await this.configureAuth(IDPType.auth0);
      this.oauthService.initLoginFlow(IDPType.auth0, {idp: IDPType.auth0 });
    }

    /**
     * Starts the login flow for Azure AD.
     */
    async loginWithAzureAD(): Promise<void> {
      await this.configureAuth(IDPType.azure);
      this.oauthService.initLoginFlow(IDPType.azure, {idp: IDPType.azure });
    }

  /**
   * Checks if the current user is authenticated with Auth0.
   *
   * @param iss - Optional issuer to check against.
   * @returns True if the user is authenticated with Auth0, false otherwise.
   */
  isAuth0IDP(iss?: 'NAN'): boolean {
    // console.log('@this.oauthService?.issuer >> ', this.oauthService?.issuer);
    // console.log('@environment?.identityProviders?.auth0?.issuer >> ', environment?.identityProviders?.auth0?.issuer);
    return new RegExp(environment?.identityProviders?.auth0?.issuer, 'ig').test(
      this.oauthService?.issuer || iss
    );
  }

  /**
   * Checks if the current user is authenticated with Azure AD.
   *
   * @param iss - Optional issuer to check against.
   * @returns True if the user is authenticated with Azure AD, false otherwise.
   */
  isAzureIDP(iss?: 'NAN'): boolean {
    return new RegExp(environment?.identityProviders?.azure?.issuer, 'ig').test(
      this.oauthService?.issuer || iss
    );
  }

  /**
   * Checks if the current user is authenticated.
   *
   * @returns True if the user is authenticated, false otherwise.
   */
  isAuthenticated(): boolean {
    return this.oauthService?.hasValidAccessToken();
  }

  /**
   * Gets the user's claims.
   *
   * @returns The user's claims if the user is authenticated, null otherwise.
   */
  getClaims(): any {
    if (!this.isAuthenticated()) {
      return null;
    }
    this.concreteUser();
    return this.loggedInUser;
  }

  /**
   * Gets the user's ID token.
   *
   * @returns The user's ID token if the user is authenticated, null otherwise.
   */
  getIDToken() {
    return this.oauthService.getIdToken();
  }

  /**
   * Gets the user's access token.
   *
   * @returns The user's access token if the user is authenticated, null otherwise.
   */
  getAccessToken() {
    return this.oauthService.getAccessToken();
  }

  /**
   * Logs out the user.
   *
   * @param withRedirect - Optional parameter to specify whether to redirect the user after logging out.
   */
  logout(withRedirect = true): void {
    const options: Record<string, any> = {};

    options.client_id = this.oauthService.clientId;
    if (withRedirect) {
      options.returnTo = this.oauthService.redirectUri;
    }

    this.oauthService.logOut({ ...options });
  }

  events() {
    return this.oauthService.events;
  }

  /**
   * Populates the loggedInUser object with the user's claims.
   */
  private concreteUser() {
    const idpClaims = this.oauthService.getIdentityClaims() as any;
    // console.log('@')
    if (!this.loggedInUser && idpClaims) {
      // console.log('@this.isAuth0IDP() >> ', this.isAuth0IDP());
      if (this.isAuth0IDP()) {
        this.loggedInUser = {
          role: idpClaims.role || '',
          firstName: idpClaims.firstName || '',
          lastName: idpClaims.lastName || '',
          phone: idpClaims.phone || '',
          customer_id: idpClaims.customer_id || '',
          child_accounts: idpClaims.child_accounts || '',
          customer_name: idpClaims.customer_name || '',
          accountManagerId: idpClaims.accountManagerId || '',
          defaultDisplayAccount: idpClaims.defaultDisplayAccount || '',
          defaultMode: idpClaims.defaultMode || '',
          defaultPage: idpClaims.defaultPage || '',
          given_name: idpClaims.given_name || '',
          family_name: idpClaims.family_name || '',
          nickname: idpClaims.nickname || '',
          name: idpClaims.name || '',
          picture: idpClaims.picture || '',
          email: idpClaims.email || '',
          email_verified: idpClaims.email_verified || false,
          idp: IDPType.auth0,
          id: idpClaims.sub || '',
        };
      } else if (this.isAzureIDP()) {
        this.loggedInUser = {
          role: [idpClaims.ADRole || ''], //
          firstName: idpClaims.firstName || '', //
          lastName: idpClaims.family_name || '', //
          phone: idpClaims.phone || '', //
          customer_id: idpClaims.customer_id || '', //
          child_accounts: idpClaims.child_accounts || '',
          customer_name: idpClaims.customer_name || '', //
          accountManagerId: idpClaims.accountManagerId || '',
          defaultDisplayAccount: idpClaims.defaultDisplayAccount || '',
          defaultMode: idpClaims.defaultMode || '',
          defaultPage: idpClaims.defaultPage || '',
          given_name: idpClaims.given_name || '',
          family_name: idpClaims.family_name || '',
          nickname:
            idpClaims.email?.substring(0, idpClaims.email.indexOf('@')) || '',
          name: idpClaims.email || '', //
          picture: idpClaims.picture || '',
          email: idpClaims.email || '', //
          email_verified: true,
          idp: IDPType.azure,
          id: idpClaims.oid || '',
        };
      }
    }
  }
}
